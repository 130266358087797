/* global grecaptcha */

import { verifyCaptcha } from './API';

import isDebug from './isDebug';

const DEFAULT_SITE_KEY = '6LdidCsqAAAAAFwA0FKvOLVgnym-PVSX0Fxy5FsP';

export default function withCaptcha(fn) {
  return async function withCaptchaFn(...args) {
    return new Promise((resolve, reject) => {

      grecaptcha.ready(() => {
        grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY || DEFAULT_SITE_KEY, { action: 'submit' }).then(async (token) => {
          const { success } = await verifyCaptcha(token);

          if (!success) {
            isDebug() && console.log('[reCAPTCHA] verification failed');

            resolve(null);
          }

          isDebug() && console.log('[reCAPTCHA] verification succeeded');

          resolve(fn(...args));
        });
      });

    });
  };
}